/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-plusplus */
/* eslint-disable no-use-before-define */
import { ContentCopyRounded } from '@mui/icons-material';
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Paper, Stack, Switch, Table, TableHead, TableBody, TableRow, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { connectNettingServer, getDicyCreds, resetPassword, updateUserPreferences, setup2FA, verify2FA, reset2FA } from '../../apiServices';
import { useUserMetadata } from '../../shared/context/UserMetadataProvider';
import { ErrorContext } from '../../shared/context/ErrorProvider';
import { StyledTableCell } from '../../shared/orderTable/util';
import { OPEN_NEW_TAB_ON_SUBMIT } from '../../constants';

export default function AccountSettings() {
  const { user, setUser, isRetail, loadUserMetadata } = useUserMetadata();
  const [dicyCreds, setDicyCreds] = useState([]);
  const [server, setServer] = useState('');
  const [is2FAEnabled, setIs2FAEnabled] = useState(false);
  const [apiTokenVisible, setApiTokenVisible] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [oldPassword, setOldPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [qrCode, setQrCode] = useState('');  
  const [twoFAToken, setTwoFAToken] = useState(['', '', '', '', '', '']); 
  const [reset2FAToken, setReset2FAToken] = useState(['', '', '', '', '', '']);
  const [qrDialogOpen, setQrDialogOpen] = useState(false); 
  const [passwordOpen, setPasswordOpen] = useState(false);
  const [resetDialogOpen, setResetDialogOpen] = useState(false);
  const { setHasError, setErrorContent } = useContext(ErrorContext);
  const inputRefs = useRef([]);

  const showAlert = ({ severity, message }) => {
    setErrorContent({ severity, message });
    setHasError(true);
  }

  useEffect(() => {
    if (user && user.user_id) {
      setIs2FAEnabled(user.is_2fa_enabled || false);
    }
  }, [user]);

  const handleInputChange = (e, index, tokenArray, setTokenState) => {
    const newToken = [...tokenArray];
    const value = e.target.value.replace(/\D/g, ''); 
    if (e.nativeEvent.inputType === 'deleteContentBackward') {
      newToken[index] = '';  
      setTokenState(newToken);
  
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
      return;
    }

    newToken[index] = value.slice(0, 1);
    setTokenState(newToken);
  
    if (newToken[index] && index < 5) {
      inputRefs.current[index + 1].focus();
    }
  };
  
  const getTokenString = (tokenArray) => tokenArray.join('');

  const handle2FASetup = async () => {
    try {
      const qrCode = await setup2FA();
      setQrCode(qrCode); 
      setQrDialogOpen(true); 
    } catch (error) {
      showAlert({ severity: 'error', message: `Failed to set up 2FA` });
    }
  };

  const handle2FAVerification = async () => {
    try {
      const token = getTokenString(twoFAToken);
      const response = await verify2FA(token);
      if (response.success) {
        showAlert({ severity: 'success', message: '2FA verified and enabled successfully!' });
        setIs2FAEnabled(true); 
        loadUserMetadata(); 
        setQrDialogOpen(false);
      } else {
        showAlert({ severity: 'error', message: response.message || 'Invalid 2FA token. Please try again.' });
      }
    } catch (error) {
      showAlert({ severity: 'error', message: `Failed to verify 2FA` });
    }
  };

  const handle2FAReset = async () => {
    try {
      const token = getTokenString(reset2FAToken);
      const response = await reset2FA(token);
      if (response.message === "2FA has been reset.") {
        setResetDialogOpen(true); 
        loadUserMetadata(); 
        setResetDialogOpen(false);
        showAlert({ severity: 'success', message: '2FA has been reset.' });
      } else {
        showAlert({ severity: 'error', message: response.message || 'Invalid 2FA token. Please try again.' });
      }
    } catch (error) {
      showAlert({ severity: 'error', message: `Failed to reset 2FA` });
    }
  };

  const handleQrDialogClose = () => {
    setQrDialogOpen(false); 
  };

  const openResetDialog = () => {
    setResetDialogOpen(true);
  };

  const closeResetDialog = () => {
    setResetDialogOpen(false); 
  };

  const toggleApiTokenVisibility = () => {
    setApiTokenVisible(!apiTokenVisible);
  };

  useEffect(() => {
    const fetchDicyCreds = async () => {
      try {
        const creds = await getDicyCreds();
        setDicyCreds(creds);
      } catch (error) {
        showAlert({severity: 'error', message: `Error fetching Dicy credentials: ${error.message}`,
          setHasError, setErrorContent});
      }
    };

    fetchDicyCreds();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await connectNettingServer(server)

      const data = await response.json();
      setServer(data.server);
    } catch (error) {
      showAlert({severity: 'error', message: `Failed to connect to netting server: ${error.message}`,
        setHasError, setErrorContent});
    }
  };

  const passwordReset = async (event) => {
    event.preventDefault();
    try {
      await resetPassword(user.user_id, oldPassword, newPassword, confirmPassword);
      showAlert({ severity: 'success', message: 'Password reset successfully!' });
      setOldPassword('');
      setNewPassword('');
      setConfirmPassword('');
      handleClose();
    } catch (error) {
      showAlert({ severity: 'error', message: `Error resetting password` });
    }
  };

  const handleClick = (event) => {
    setPasswordOpen(true);
  };

  const handleClose = () => {
    setPasswordOpen(false);
  };

  const handlePreferenceSwitchChange = async (name, value) => {
    user.preferences[name] = value;
    setUser(user);
    try {
      await updateUserPreferences({[name]: value});
    } catch (error) {
      showAlert({severity: 'error', message: `Failed to update preferences: ${error.message}`,
        setHasError, setErrorContent});
    }

    loadUserMetadata();
  }

  const authTokenHeader = dicyCreds.api_token && `Authorization: Token ${dicyCreds.api_token.key}`;
  const copyCodeToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(authTokenHeader);
    } catch (err) {
      // nothing
    }
  }

  const renderPrefName = (name) => {
    if (name === OPEN_NEW_TAB_ON_SUBMIT) {
      return 'Open new tab on order submit';
    }

    return name;
  }

  const renderValueAsInputField = (name, value) => {
    if (typeof(value) === 'boolean') {
      return (
        <Switch
          checked={value}
          onChange={(e) => {
            handlePreferenceSwitchChange(name, e.target.checked);
          }}
        />
      );
    }

    // figure out how to render other types of values
    return <Typography>{value}</Typography>
  }

  if (Object.keys(user).length === 0) {
    return <div/>
  }

  return (
    <Stack
      spacing={1}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'auto',
      }}
    >
      <Paper sx={{ p: 2, mb: 4, width: '75%' }}>
        <Typography gutterBottom variant="h3">
          Profile
        </Typography>
        {(user && Object.keys(user).length > 0) && (
          <Table>
            <TableBody>
              <TableRow>
                <StyledTableCell>Username</StyledTableCell>
                <StyledTableCell>{user.username}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>Email</StyledTableCell>
                <StyledTableCell>{user.email}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>Password</StyledTableCell>
                <StyledTableCell>
                  <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    color="secondary"
                    size="small"
                    variant="contained"
                    onClick={handleClick}
                  >
                    <Typography>Reset Password</Typography>
                  </Button>
                  <Dialog

                    id="password-dialog"
                    open={passwordOpen}
                    onClose={handleClose}
                  >
                    <DialogTitle>
                      Reset Password
                      <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        style={{
                          position: 'absolute',
                          right: 8,
                          top: 8,
                          color: '#fff',
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </DialogTitle>
                    <DialogContent
                      style={{
                        padding: '2rem',
                        borderRadius: '10px',
                        color: '#fff',
                      }}
                    >
                      <Stack direction="column" spacing={2}>
                        <TextField
                          label="Old Password" 
                          type="password"
                          value={oldPassword}
                          onChange={(e) => setOldPassword(e.target.value)}
                          fullWidth
                          InputProps={{
                            style: {
                              backgroundColor: 'rgba(255, 255, 255, 0.2)',
                              color: 'rgba(255, 255, 255, 0.7)',
                            },
                          }}
                          variant="outlined"
                        />
                        <TextField
                          label="New Password"
                          type="password"
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                          fullWidth
                          InputProps={{
                            style: {
                              backgroundColor: 'rgba(255, 255, 255, 0.2)',
                              color: 'rgba(255, 255, 255, 0.7)',
                            },
                          }}
                          variant="outlined"
                        />
                        <TextField
                          label="Confirm Password" 
                          type="password"
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          fullWidth
                          InputProps={{
                            style: {
                              backgroundColor: 'rgba(255, 255, 255, 0.2)',
                              color: 'rgba(255, 255, 255, 0.7)',
                            },
                          }}
                          variant="outlined"
                        />
                        <Typography sx={{ fontSize: 14, marginTop: 2, color: 'rgba(255, 255, 255, 0.7)' }}>
                          To secure your account,
                          <br />
                          your new password must contain at least:
                          <ul>
                            <li>8 characters long</li>
                            <li>1 uppercase letter</li>
                            <li>1 lowercase letter</li>
                            <li>1 number</li>
                            <li>1 special character (e.g., !, @, #, $, etc.)</li>
                          </ul>
                        </Typography>
                        <Box>
                          <Stack direction="row" spacing={2}>
                            <Button
                              variant="contained"
                              onClick={passwordReset}
                              style={{
                                backgroundColor: '#FFB56A',
                                color: 'rgba(0, 0, 0, 0.7)',
                                marginTop: '1rem',
                              }}
                            >
                              Reset Password
                            </Button>
                            <Button
                              variant="contained"
                              onClick={handleClose}
                              style={{
                                backgroundColor: '#FFB56A',
                                color: 'rgba(0, 0, 0, 0.7)',
                                marginTop: '1rem',
                                marginLeft: 'auto',
                              }}
                            >
                              Close
                            </Button>
                          </Stack>
                        </Box>
                      </Stack>
                    </DialogContent>
                  </Dialog>
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell>Account Created</StyledTableCell>
                <StyledTableCell>{user.date_joined}</StyledTableCell>
              </TableRow>
              { isRetail && <TableRow>
                <StyledTableCell>Referral Code</StyledTableCell>
                <StyledTableCell>{user.referral_code}</StyledTableCell>
              </TableRow>
              }
            </TableBody>
          </Table>
        )}
      </Paper>

      {!isRetail &&
      <Paper sx={{ p: 2, mb: 4, width: '75%', position: 'relative', minHeight: '85px'}}>
        <Button
          color="secondary"
          sx={{top: 16, right: 16, position:'absolute'}}
          variant="contained"
          onClick={toggleApiTokenVisibility}>
          <Typography>{ apiTokenVisible ? "Hide API Token" : "Show API Token" }</Typography>
        </Button>
        <Stack direction='column' spacing={2}>
          <Typography gutterBottom variant="h3">
            API Key
          </Typography>
          {(apiTokenVisible && dicyCreds && dicyCreds.api_token) && (
            <Stack direction="row" position='relative' width='100%' >
              <Typography
                component="pre"
                sx={{
                  fontFamily: 'monospace',
                  color: 'error.main',
                  backgroundColor: 'grey.dark',
                  borderRadius: '4px',
                  padding: '8px',
                  overflowX: 'auto',
                  width: '100%',
                }}
              >
                {authTokenHeader}
              </Typography>
              <IconButton
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  padding: '8px',
                }}
                onClick={copyCodeToClipboard}
              >
                <ContentCopyRounded />
              </IconButton>
            </Stack>
          )}
        </Stack>
      </Paper>
      }
      <Paper sx={{p: 2, width: '75%'}}>
        <Stack direction="column" spacing={2}>
          <Typography gutterBottom variant="h3">
            Preferences
          </Typography>

          <Table>
            <TableBody>
              {
                Object.entries(user.preferences).map(([name, value]) => (
                  <TableRow key={name}>
                    <StyledTableCell>{renderPrefName(name)}</StyledTableCell>
                    <StyledTableCell>{renderValueAsInputField(name, value)}</StyledTableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </Stack>
      </Paper>
      <Paper sx={{ p: 2, mb: 4, width: '75%', position: 'relative', minHeight: '85px'}}>
        <Typography
          sx={{
            color: 'white',
            fontSize: 24,
            fontFamily: 'IBM Plex Mono',
            fontWeight: '400',
            lineHeight: '32.02px',
            wordWrap: 'break-word',
          }}
        >
          Two-factor authentication
        </Typography>

        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1 }}>
          <Box>
            <Typography
              sx={{
                color: 'white',
                fontSize: 16,
                fontFamily: 'IBM Plex Mono',
                fontWeight: '400',
                lineHeight: '28px',
                letterSpacing: '0.15px',
                wordWrap: 'break-word',
                mb: 0.5,
              }}
            >
              Authenticator app
            </Typography>
            <Typography
              sx={{
                color: 'rgba(255, 255, 255, 0.70)',
                fontSize: 14,
                fontFamily: 'IBM Plex Mono',
                fontWeight: '400',
                lineHeight: '21px',
                letterSpacing: '0.15px',
                wordWrap: 'break-word',
              }}
            >
              Secure your account by enabling two-factor authentication with an authenticator app.
            </Typography>
          </Box>
          <Button
            variant="contained"
            onClick={is2FAEnabled ? openResetDialog : handle2FASetup}
            sx={{
              width: '85px',
              height: '36px',
              backgroundColor: '#62526C',
              boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.20)',
              borderRadius: 1,
              padding: '6px 16px',
              color: '#F3E5F5',
              fontSize: 14,
              fontFamily: 'IBM Plex Mono',
              fontWeight: '500',
              lineHeight: '24px',
              letterSpacing: '0.40px',
            }}
          >
            {is2FAEnabled ? 'Reset' : 'Enable'}
          </Button>
        </Box>
      </Paper>
      <Dialog open={qrDialogOpen} onClose={handleQrDialogClose}>
        <DialogContent
          sx={{
            width: 'auto',
            maxWidth: '380px',  
            padding: '24px',
            borderRadius: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleQrDialogClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box sx={{ width: '100%', marginBottom: '16px' }}>
            <Typography
              sx={{
                color: 'white',
                fontSize: 20,
                fontFamily: 'IBM Plex Mono',
                fontWeight: '500',
                lineHeight: '32px',
                letterSpacing: '0.15px',
              }}
            >
              Enable authenticator app
            </Typography>
            <Typography
              sx={{
                color: 'rgba(255, 255, 255, 0.70)',
                fontSize: 12,
                fontFamily: 'Inter',
                fontWeight: '500',
                lineHeight: '22px',
                marginTop: '8px',
              }}
            >
              Secure your account by enabling two-factor authentication with an authenticator app.
            </Typography>
          </Box>
          <Box
            sx={{
              padding: '10px',
              background: 'white',
              borderRadius: '6px',
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '24px',
            }}
          >
            <img src={qrCode} alt="QR Code" style={{ width: '167px', height: '162px', borderRadius: '6px' }} />
          </Box>
          <Box sx={{ width: '100%' }}>
            <Typography
              sx={{
                color: 'white',
                fontSize: 14,
                fontFamily: 'Inter',
                fontWeight: '600',
                lineHeight: '17.5px',
                marginBottom: '8px',
              }}
            >
              Authentication App code
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center', 
                gap: '18px',  
                marginBottom: '10px',
              }}
            >
              {twoFAToken.map((digit, index) => (
                <TextField
                  key={index}
                  value={digit}
                  onChange={(e) => handleInputChange(e, index, twoFAToken, setTwoFAToken)}
                  inputRef={(el) => (inputRefs.current[index] = el)}
                  inputProps={{
                    maxLength: 1,
                    style: {
                      textAlign: 'center',
                      fontSize: '16px',
                      width: '18px', 
                      height: '25px', 
                      borderRadius: '4px',
                      border: '1px solid rgba(255, 181, 106, 0.5)',
                    },
                    autoComplete: 'off',
                  }}
                />
              ))}
            </Box>
            <Typography
              sx={{
                color: 'rgba(255, 255, 255, 0.6)',
                fontFamily: 'Inter',
                fontSize: 12,
                lineHeight: '16px',
                marginBottom: '16px',
              }}
            >
              Enter the 6-digit code generated by your authenticator app to complete setup.
            </Typography>
          </Box>
          <Button
            onClick={handle2FAVerification}
            sx={{
              width: '100%',
              backgroundColor: '#FFA345',
              color: 'rgba(0, 0, 0, 0.87)',
              fontSize: 14,
              fontFamily: 'IBM Plex Mono',
              fontWeight: '500',
              lineHeight: '24px',
              letterSpacing: '0.40px',
            }}
            variant="contained"
          >
            Enable 2FA
          </Button>
        </DialogContent>
      </Dialog>
      <Dialog open={resetDialogOpen} onClose={closeResetDialog}>
        <DialogContent
          sx={{
            width: 'auto',
            maxWidth: '380px',
            padding: '24px',
            borderRadius: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <IconButton
            aria-label="close"
            onClick={closeResetDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
            }}
          >
            <CloseIcon />
          </IconButton>
          <Box sx={{ width: '100%', marginBottom: '16px' }}>
            <Typography
              sx={{
                color: 'white',
                fontSize: 20,
                fontFamily: 'IBM Plex Mono',
                fontWeight: '500',
                lineHeight: '32px',
                letterSpacing: '0.15px',
              }}
            >
              Reset 2FA
            </Typography>
            <Typography
              sx={{
                color: 'rgba(255, 255, 255, 0.70)',
                fontSize: 12,
                fontFamily: 'Inter',
                fontWeight: '500',
                lineHeight: '22px',
                marginTop: '8px',
              }}
            >
              Write your 2FA code to reset.
            </Typography>
          </Box>
          <Box sx={{ width: '100%' }}>
            <Typography
              sx={{
                color: 'white',
                fontSize: 14,
                fontFamily: 'Inter',
                fontWeight: '600',
                lineHeight: '17.5px',
                marginBottom: '8px',
              }}
            >
              Authentication App code
            </Typography>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: '18px',
                marginBottom: '10px',
              }}
            >
              {reset2FAToken.map((digit, index) => (
                <TextField
                  key={index}
                  value={digit}
                  onChange={(e) => handleInputChange(e, index, reset2FAToken, setReset2FAToken)}
                  inputRef={(el) => (inputRefs.current[index] = el)}
                  inputProps={{
                    maxLength: 1,
                    style: {
                      textAlign: 'center',
                      fontSize: '16px',
                      width: '18px',
                      height: '25px',
                      borderRadius: '4px',
                      border: '1px solid rgba(255, 181, 106, 0.5)',
                    },
                    autoComplete: 'off',
                  }}
                />
              ))}
            </Box>
            <Typography
              sx={{
                color: 'rgba(255, 255, 255, 0.6)',
                fontFamily: 'Inter',
                fontSize: 12,
                lineHeight: '16px',
                marginBottom: '16px',
              }}
            >
              Enter the 6-digit code generated by your authenticator app to reset 2FA.
            </Typography>
          </Box>
          <Button
            onClick={handle2FAReset}
            sx={{
              width: '100%',
              backgroundColor: '#FFA345',
              color: 'rgba(0, 0, 0, 0.87)',
              fontSize: 14,
              fontFamily: 'IBM Plex Mono',
              fontWeight: '500',
              lineHeight: '24px',
              letterSpacing: '0.40px',
            }}
            variant="contained"
          >
            Reset 2FA
          </Button>
        </DialogContent>
      </Dialog>
      {!isRetail &&
      <Paper sx={{ p: 2, width: '75%' }}>
        <Stack direction="column" spacing={2}>
          <Typography gutterBottom variant="h3">
            Internal Netting
          </Typography>
          <form action="/account/connect/" id="add_dicy_server" method="post" onSubmit={handleSubmit}>
            <Stack direction="row" spacing={2}>
              <TextField
                fullWidth
                placeholder='Server'
                value={server}
                onChange={(e) => setServer(e.target.value)}
              />
              <Button
                color='secondary'
                sx={{whiteSpace: 'nowrap'}}
                type="submit"
                variant="contained"
              >
                Connect
              </Button>
            </Stack>
          </form>
          {Object.keys(dicyCreds).length > 0 && dicyCreds.dicy_credentials && (
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Server</StyledTableCell>
                  <StyledTableCell>User ID</StyledTableCell>
                  <StyledTableCell>API Key</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  dicyCreds.dicy_credentials.map((cred) => (
                    <TableRow key={cred.dicy_user_name}>
                      <StyledTableCell>{cred.server}</StyledTableCell>
                      <StyledTableCell>{cred.dicy_user_name}</StyledTableCell>
                      <StyledTableCell>{cred.dicy_token}</StyledTableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          )}
        </Stack>
      </Paper>
      }
    </Stack>
  );
}
